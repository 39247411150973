<template>
  <div>
    <v-img :src="require('../assets/fog-3622519_1280.jpg')" max-height="300">
      <v-container>
        <div class="text-lg-h3 text-md-h3 text-sm-h4 text-xs-h4 mt-10">
          Baumquiz
        </div>
        <div class="text-lg-h5 text-sm-h6">
          Kennst du die Bäume in unseren Wäldern?
        </div>
      </v-container>
    </v-img>
    <v-container>
      <v-row class="my-10 justify-center">
          <v-card max-width="350" class="mx-5 my-5">
            <v-img
              :src="require('../assets/road-1072823_1280.jpg')"
              max-height="200"
              dark
              class="align-end"
            >
              <v-card-title> Rätselspaß </v-card-title>
            </v-img>
            <v-card-text>
              Kennst du die Situation? Du spazierst genüsslich in der Natur und
              fragst dich ständig, welcher <b>Baum</b> das denn da am Wegesrand
              steht?<br />
              Falls ja, dann bist du beim <b>Baumquiz</b> genau richtig. Hier
              kannst du spielerisch die typisch europäischen
              <b>Bäume kennenlernen</b>.
            </v-card-text>
            <v-card-actions>
              <router-link to="/quiz">
                <v-btn text color="green darken-3"> Baumquiz starten </v-btn>
              </router-link>
            </v-card-actions>
          </v-card>
        
          <v-card max-width="350" class="mx-5 my-5">
            <v-img
              :src="require('../assets/trees-1587301_1280.jpg')"
              max-height="200"
              class="align-end"
              dark
            >
              <v-card-title> Baumkatalog </v-card-title>
            </v-img>

            <v-card-text>
              Im <b>Baumkatalog</b> kannst du dir alle Bäume vorher ansehen und
              einprägen. Hier kannst du einfach zwischen den Bäumen schmökern
              und den Katalog als <b>Nachschlagewerk</b> nutzen.
            </v-card-text>
            <v-card-actions>
              <router-link :to="{name: 'Catalogue' }">
                <v-btn text color="green darken-3"> Baumkatalog öffnen </v-btn>
              </router-link>
            </v-card-actions>
          </v-card>
       
          <v-card max-width="350" class="mx-5 my-5">
            <v-img
              :src="require('../assets/forest-3287976_1280.jpg')"
              max-height="200"
              class="align-end"
              dark
            >
              <v-card-title>Weitere Baumquizze</v-card-title>
            </v-img>
            <v-card-text>
              Frei nach dem Motto "Andere Mütter haben auch schöne Töchter, gibt es im Internet bereits eine Reihe anderer Rätsel um dein Wissen über Bäume zu verbessern."
            </v-card-text>
            <v-card-actions>
              <router-link :to="{name: 'OtherQuizzes' }">
                <v-btn text color="green darken-3"> Zeig's mir </v-btn>
              </router-link>
            </v-card-actions>
          </v-card>
        
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Welcome",
  components: {},
  data: () => ({}),
  metaInfo: {
    title: 'Das Quiz um spielerisch Bäume identifizieren zu lernen - Baumquiz',
    meta: [
      { property: "description", content: 'Lerne durch das Baumquiz spielerisch die wichtigsten Bäume der heimischen Natur kennen.' },
      { property: 'og:description', content: 'Lerne durch das Baumquiz spielerisch die wichtigsten Bäume der heimischen Natur kennen.' }
    ],
  },
};
</script>



